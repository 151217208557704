import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack, Typography, Chip } from "@mui/material";
import { IconKey, IconLock, IconLockOpen, IconSend } from "@tabler/icons-react";
import MainCard from "ui/MainCard";
import If from "ui/If/If";
import { hideConfirm, showConfirm } from "store/confirm/actions";
import { lockAccount, resetPassword, resendWelcomeEmail, fetchProfileDetail } from "store/profiles/actions";
import { fetchAllOrganizations, removeUserFromOrganization } from "store/organizations/actions";

const ProfileCard = () => {
    const profile = useSelector((state) => state?.profiles?.selectedProfile);
    const isConfirmed = useSelector((state) => state?.confirm?.isConfirmed);
    const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dynamicTitle = `${profile?.firstName} ${profile?.lastName}`;

    useEffect(() => {
        if (!organizations || organizations?.length === 0) {
            dispatch(fetchAllOrganizations());
        }
    }, [organizations])

    useEffect(() => {
        if (isConfirmed) {
            setTimeout(() => {
                dispatch(fetchProfileDetail(profile.caregiverId));
                dispatch(hideConfirm(false));
            }, 500);
        }
    }, [isConfirmed])

    const handleResendEmail = () => {
        dispatch(showConfirm("Confirm",
            `Are you sure you want to re-send welcome email to ${profile?.firstName} ${profile?.lastName} ?`,
            resendWelcomeEmail,
            [profile.caregiverId]))
    }

    const handleResetPassword = () => {
        dispatch(showConfirm("Confirm",
            `Are you sure you want to send password reset email to ${profile?.firstName} ${profile?.lastName} ?`,
            resetPassword,
            [profile.caregiverId]))
    }

    const handleLockAccount = () => {
        dispatch(showConfirm("Confirm",
            `Are you sure you want to ${profile?.isEnabled ? "lock" : "unlock"} this account - ${profile?.firstName} ${profile?.lastName} ?`,
            lockAccount,
            [profile.caregiverId, !profile.isEnabled]))
    }

    const handleRedirect = (orgName) => {
        if (organizations?.length === 0) {
            return;
        }

        const selectedOrg = organizations.find(org => org.name === orgName);
        navigate(`/organizations/${selectedOrg.id}`)
    }

    const handleDelete = (organizationName) => {
        const organization = organizations.find(org => org.name === organizationName);
        dispatch(showConfirm("Remove connection", `Are you sure you want to remove ${dynamicTitle} from "${organizationName}" ?`,
            removeUserFromOrganization, [profile.caregiverId, `${profile.firstName} ${profile.lastName}`, organization.id, organization.name]));
    };


    return (
        <MainCard title={dynamicTitle}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">
                        <b>Email:</b> {profile?.email}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">
                        <b>Id:</b> {profile?.caregiverId}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">
                        <b>Role:</b> {profile?.role}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2">
                        <b>Account Status:</b> {profile?.isEnabled === true ? "Active" : "Deactivated"}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8} sx={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", flexBasis: "100%" }}>
                    <Typography variant="body2">
                        <b>Organization(s):</b>
                    </Typography>
                    <Chip
                        sx={{ marginLeft: 1, marginBottom: 1 }}
                        size="small"
                        label={profile?.primaryOrganizationName}
                        color="primary"
                        variant="outlined"
                        onClick={() => handleRedirect(profile?.primaryOrganizationName)}
                        onDelete={() => handleDelete(profile?.primaryOrganizationName)}
                        title="Primary Organization"
                    />
                    {profile?.organizationNames?.map((organizationName) => (
                        <If when={organizationName !== profile?.primaryOrganizationName}>
                            <Chip
                                sx={{ marginLeft: 1, marginBottom: 1 }}
                                size="small"
                                label={organizationName}
                                variant="outlined"
                                onClick={() => handleRedirect(organizationName)}
                                onDelete={() => handleDelete(organizationName)}
                            />
                        </If>
                    ))}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} marginTop={3}>
                    <Stack className="buttons-stack"
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent="flex-start"
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Button variant="outlined" color="success" onClick={handleResendEmail}>
                            <IconSend stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Resend Welcome E-mail
                        </Button>
                        <Button variant="outlined" onClick={handleResetPassword}>
                            <IconKey stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Reset Password
                        </Button>
                        <Button variant="contained" color="error" onClick={handleLockAccount}>
                            <If when={profile?.isEnabled}>
                                <IconLock stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Lock Account
                            </If>
                            <If when={!profile?.isEnabled}>
                                <IconLockOpen stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Unlock Account
                            </If>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard >
    );
}

export default ProfileCard;