import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.type";

const searchProfilesRequest = () => ({
  type: actionTypes.SEARCH_PROFILES_REQUEST,
});

const searchProfilesSuccess = (payload) => ({
  type: actionTypes.SEARCH_PROFILES_SUCCESS,
  payload,
});

const searchProfilesFailed = (payload) => ({
  type: actionTypes.SEARCH_PROFILES_FAILURE,
  payload,
});

export const searchProfiles = (searchText) => {
  const url = `/api/bridge/search?q=${searchText}`;

  if (!searchText.trim()) {
    return searchProfilesSuccess([]);
  }

  const actions = {
    request: searchProfilesRequest,
    success: searchProfilesSuccess,
    failure: searchProfilesFailed,
  };
  return httpClient.run(Api.get, url, {}, actions);
};

export const setSelectedProfile = (payload) => ({
  type: actionTypes.SET_SELECTED_PROFILE,
  payload,
});

export const updateProfileInfo = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_INFO,
  payload,
});

export const refreshProfiles = (data, selectedProfileId) => {
  const url = `/api/bridge/user/${selectedProfileId}/info`;
  const actions = {
    request: searchProfilesRequest,
    success: updateProfileInfo,
  };

  return httpClient.run(Api.get, url, {}, actions);
};

export const resendWelcomeEmail = (id) => {
  const url = `/api/bridge/user/${id}/email/welcome`;
  const messages = {
    success: "Welcome Email Resent Successfully!",
    failure: "Failed to Resend Welcome Email. Please try again.",
  };
  return httpClient.run(Api.post, url, {}, {}, messages);
};

export const resetPassword = (id) => {
  const url = `/api/bridge/user/${id}/email/passwordReset`;
  const messages = {
    success: "Password Reset Email Sent Successfully!",
    failure: "Oops! Something Went Wrong, Please Try Later!",
  };
  return httpClient.run(Api.post, url, {}, {}, messages);
};

export const lockAccount = (id, status) => {
  const url = `/api/bridge/user/${id}/enable/${status}`;
  const actions = { success: refreshProfiles, successParams: id };
  const messages = {
    success: "Account Status Updated Successfully!",
    failure: "Failed to Update Account Status. Please try again.",
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};

const fetchProfileRequest = () => ({
  type: actionTypes.FETCH_PROFILE_REQUEST,
});

const fetchProfileSuccess = (payload) => ({
  type: actionTypes.FETCH_PROFILE_SUCCESS,
  payload,
});

const fetchProfileFailed = (payload) => ({
  type: actionTypes.FETCH_PROFILE_FAILURE,
  payload,
});

export const fetchProfileDetail = (id) => {
  const url = `/api/bridge/user/${id}/info`;
  const actions = {
    request: fetchProfileRequest,
    success: fetchProfileSuccess,
    failure: fetchProfileFailed,
  };
  return httpClient.run(Api.get, url, {}, actions);
};
